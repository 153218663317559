import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ExtLink from "../components/ext-link"

export default function UeberUnsPage() {
  return (
    <Layout className="no-sidebar">
      <SEO title="Über uns" />

      <div className="wrapper">
        <div className="container" id="main">
          <article id="content">
            <header>
              <h2>Über uns</h2>
            </header>

            <header>
              <h3>Unsere Geschäftsleitung</h3>
            </header>
            
            <header>
              <h4>Dr. Karl-Michael Meiß, CEO</h4>
            </header>
            <p>
              Herr Dr.Ing. Karl-Michael Meiß hat langjährige Erfahrung im Bereich der
              Biomassekonversion, und zwar sowohl in technischer Hinsicht, insbesondere in
              puncto Produktionssteuerung, als auch hinsichtlich der Vermarktung und
              Finanzierung. Er ist Diplom Agrar-Ingenieur und promovierter Experte für
              Produktionssysteme. Durch seine langjährige Tätigkeit in der Forschung und
              Industrie kann er die Anwendungsmöglichkeiten von Technologien sehr gut
              einschätzen.
            </p>
            <p>
              Unterstützt wird Herr Dr. Meiß von einem wissenschaftlichen Beirat, der sich
              aus hochqualifizierten Persönlichkeiten aus dem Kreis der Arnold-Sommerfeld-
              Gesellschaft e.V. Leipzig (<ExtLink href="http://www.asg-ev.de" />)
              zusammensetzt, die sich schon seit vielen
              Jahren mit dem Thema Reststoffverwertung auseinandersetzen. Diese Personen
              sind an unterschiedlichen deutschen Universitäten tätig und haben hierzu zahlreiche
              Veröffentlichungen in der VDI Zeitschrift getätigt.
            </p>

            <Beiraete />
          </article>
        </div>
      </div>
    </Layout>
  )
}

function Beiraete() {
  const persons = [{
    name: 'Prof. em. Dr. rer. nat. habil. Bernd Michel',
    link: 'http://u01082411546.user.hosting-agency.de/'
  }, {
    name: 'Prof. em. Dr. rer. nat. Wolf Dieter Grossmann',
    link: 'http://blog.hawaii.edu/pacon/files/2010/07/GrossmannBioPic.pdf'
  }, {
    name: 'Prof. em. Dr.-Ing. habil. Siegfried Altmann',
    link: 'https://de.wikipedia.org/wiki/Siegfried_Altmann_(Ingenieurwissenschaftler)'
  }, {
    name: 'Prof. em. Dr. rer. nat. habil. Wolfgang Babel',
    link: 'http://www.leipzig-netz.de/index.php5/ASG.2011-12-05'
  }]

  return (
    <>
      <header>
        <h3>Unsere Beiräte</h3>
      </header>

      {persons.map(p => (
        <>
          <header style={{ marginBottom: 5 }}>
            <h4>{p.name}</h4>
          </header>
          <p><ExtLink href={p.link} /></p>
        </>
      ))}
    </>
  )
}
